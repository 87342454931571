import { ThemeProvider, useTheme } from "@/components/theme-provider";
import {
  RainbowKitProvider,
  darkTheme,
  getDefaultConfig,
  getDefaultWallets,
  lightTheme,
} from "@rainbow-me/rainbowkit";
import type { Theme } from "@rainbow-me/rainbowkit";
import merge from "lodash.merge";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet, createRootRoute } from "@tanstack/react-router";
import { Toaster } from "sonner";
import { WagmiProvider, http } from "wagmi";
import { mainnet, mode, sepolia } from "wagmi/chains";

const queryClient = new QueryClient();
const projectId = "418b185772af0b4ca6601044a993ffb2";
const { wallets } = getDefaultWallets();

const config = getDefaultConfig({
  appName: "Ebisu Finance",
  projectId,
  chains:
    process.env.NODE_ENV === "production"
      ? [mainnet, mode]
      : [sepolia, mode],
  wallets,
  transports: {
    [mainnet.id]: http("https://eth-mainnet.g.alchemy.com/v2/Vl3sr7SwyDJMOMltG5knmzCh5BXeZ-d6"),
    [sepolia.id]: http("https://eth-sepolia.g.alchemy.com/v2/vxebKqxgZ-ypugJGJkRYZBCI6NPQCn7w"),
    [mode.id]: http("https://mainnet.mode.network"),
  },
});

export const Route = createRootRoute({
  component: () => (
    <ThemeProvider defaultTheme="light" storageKey="ebisu-theme">
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <WalletProvider>
            <Toaster position="top-center" />
            <Outlet />
          </WalletProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </ThemeProvider>
  ),
});

const ebisuDarkTheme: Theme = merge(darkTheme(), {
  colors: {
    connectButtonBackground: "#051034",
  },
});

const ebisuLightTheme: Theme = merge(lightTheme(), {
  shadows: null,
});

function WalletProvider({ children }: { children: React.ReactNode }) {
  const { theme } = useTheme();
  return (
    <RainbowKitProvider
      appInfo={{ appName: "Ebisu Finance" }}
      initialChain={sepolia.id}
      theme={theme === "light" ? ebisuLightTheme : ebisuDarkTheme}
    >
      {children}
    </RainbowKitProvider>
  );
}
